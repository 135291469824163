.App {
	text-align: center;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
		Helvetica Neue, Arial, Noto Sans, sans-serif;
}

.textColor {
	color: #292929 !important;
}

.bgColor {
	background-color: #292929 !important;
}

.buttonColor {
	background-color: #292929 !important;
}

.buttonActiveColor {
	color: #292929 !important;
	background-color: white !important;
}

.activeButtonColor {
	background-color: #292929 !important;
	color: white !important;
}

.activeBorder {
	border: 2px solid white !important;
}

.hr {
	height: 30px !important;
	opacity: 1 !important;
	background-color: #292929 !important;
	-webkit-mask: url(./Files/border.svg) 50% 0 no-repeat !important;
	mask: url(./Files/border.svg) 50% 0 no-repeat !important;
}

.MuiInputBase-formControl::before,
.MuiInputBase-formControl::after,
.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
	border-color: #292929 !important;
}

#nestedBottom {
	display: none;
}

#nestedTop:hover #nestedBottom {
	display: block;
}

#nestedBottom2 {
	display: none;
}

#nestedTop2:hover #nestedBottom2 {
	display: block;
}

.video-wrapper {
	width: 100%;
	height: calc(100vw * 9 / 16);
	position: relative;
}

.video-wrapper video {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.dashboard .MuiDrawer-paper {
	background: #252525 !important;
}

.dashboard .MuiDrawer-paper {
	background: #252525 !important;
}

.MuiListItem-root {
	border: 1px solid white;
	color: white !important;
}

.MuiListItem-root:hover {
	background-color: white !important;
	color: black !important;
}

.MuiListItem-root:hover .MuiListItemIcon-root {
	color: black !important;
}

.MuiListItemIcon-root {
	color: white !important;
}

.importantOptions .MuiButton-root {
	border-color: #ff700a !important;
}

.MuiTabs-scrollButtons.Mui-disabled {
	opacity: 0.3 !important;
}

.MuiAppBar-root {
	background-color: #252525 !important;
}

.MuiTabScrollButton-root {
	background-color: white !important;
	color: black !important;
}

/* Receipt */

.receipt {
	width: 900px;
	min-height: 900px;
	border: 1px solid black;
	padding: 20px;
	margin: 0 auto;
}

.receipt h1,
.receipt h3 {
	margin-top: 0;
	text-align: center;
}

.receipt table {
	border-collapse: collapse;
	width: 100%;
}

.receipt th,
.receipt td {
	border: 1px solid black;
	padding: 5px;
}

.receipt .header {
	text-align: center;
}

.receipt img {
	width: 100px;
	margin: 0 auto;
}

.receipt .margin {
	margin: 7px;
}

.swal2-container {
	z-index: 20000 !important;
}

.react-calendar {
	width: 100% !important;
	height: 100% !important;
	padding: 0.5rem !important;
}

.react-calendar__tile {
	font-size: 1.3rem !important;
}

.highlighted {
	background-color: #00805b !important;
	color: white !important;
	border: 1px solid white !important;
}

/* .blocked {
	pointer-events: none !important;
} */

.react-calendar__tile--now {
	background-color: #292929 !important;
	color: white !important;
	border: 1.3px solid white !important;
}

.react-calendar__month-view__weekdays__weekday {
	font-weight: bold !important;
	font-size: 1rem !important;
	color: #292929 !important;
	background-color: #dad9e5 !important;
}

.react-calendar__month-view__weekdays__weekday abbr[title] {
	text-decoration: none !important;
}

.react-calendar__navigation__label__labelText--from {
	font-size: 1.3rem !important;
	color: #292929 !important;
}

.react-calendar__navigation button {
	font-size: 2rem !important;
	color: #292929 !important;
}